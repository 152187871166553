.activityCard {
  background-color: var(--white);
  border-radius: 8px;
  border: var(--border);
  overflow: hidden;
}

.activityCardHead {
  border-bottom: 0.5px solid #22222210;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 24px 24px 16px;
}

.activityCardBody {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
}

.activityCardItem {
  display: grid;
  grid-template-columns: 38px 1fr;
  grid-template-rows: 1fr;
  gap: 16px;
  width: 100%;
  justify-content: flex-start;
}

.activityCardIcon {
  border-radius: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
}

.activityCardIcon {
  background-color: var(--lightest);
}

.activityCardStat {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.activityCardTitle {
  color: var(--medium);
  font-size: 0.9rem;
  line-height: 1;
  font-weight: 500;
}

.activityCardValue {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1;
}

.activityCardValue span:nth-child(2) {
  color: #60606080;
  font-size: 0.85rem;
}

.activityCardSoon {
  background-color: #F1E9F6;
  color: #6B16A6;
  border-radius: 2px;
  font-size: 12px;
  line-height: 12px;
  padding: 2px 4px;
}