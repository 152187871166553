.statusBadge {
  border-radius: 24px;
  font-size: 0.65rem;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 24px;
  width: 100%;
  padding: 0px 16px;
  justify-self: flex-end;
  text-transform: uppercase;
  text-align: center;
}

.statusBadge[data-status="active"] {
  background-color: #2c7e2c20;
  color: #227622;
}

.statusBadge[data-status="closed"] {
  background-color: #80008010;
  color: #800080;
}

.statusBadge[data-status="pending"] {
  background-color: #38363710;
  color: #383637;
}