.messageCard {
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 16px;
  margin-bottom: 24px;
}

.messageCardIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.messageCard div {
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 24px;
  text-wrap: wrap;
}